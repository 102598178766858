<template>
    <section class="pl-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 226px)">
        <cargando v-if="cargando" />
        <div v-infinite-scroll="getProducts" infinite-scroll-disabled="stop" infinite-scroll-immediate-check="false" infinite-scroll-distance="4">
            <div v-for="(data, index) in productos" :key="index" class="row mx-0 align-items-center pr-md-0 pr-sm-0 pr-0 mb-5">
                <p class="col px-0 f-28 text-general titles f-600">
                    {{ data.nombre }}
                </p>
                <div class="d-middle cr-pointer" @click="$router.push({name: 'invitado.categoria.ver', params:{ idCategoria: data.id }})">
                    <span class="f-20 text-purple text-arrow">Vér más</span>
                    <i class="icon-right-open mt-1 f-17 text-purple mr-2" />
                </div>
                <div class="col-12" />
                <div class="like-bar mb-2" />
                <div class="col-12 px-0 scroll-none overflow-auto">
                    <div class="mt-2" style="display:inline-flex;">
                        <div v-for="(info, index1) in data.productos" :key="index1">
                            <invitado-producto :producto="info" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import Service from '~/services/invitado/invitado'
import Cargando from '~/components/cargando.vue';
import { mapGetters } from 'vuex';

export default {
    directives: { infiniteScroll },
    components: { Cargando },
    data(){
        return {
            cargando: true,
            stop: false,
            productos: [],
            page: 0,
        };
    },
    computed:{
        ...mapGetters({
            tienda: 'invitado/tienda',
        }),
    },
    mounted(){
        this.getProducts();
    },
    methods: {
        async getProducts(){
            try {
                if (this.stop)return;

                this.cargando = true;

                this.page = this.page + 1;
                this.stop = true;
                const params = {
                    idTienda: this.tienda.id_tienda,
                    page: this.page,
                    limit: 4
                };
                const idCategoria = 0
                const { data } = await Service.getProductosCategoria(idCategoria, params);
                this.$emit('countProductos',data.total_productos)
                this.productos = [...this.productos, ...data.productos.data];
                if (data.productos.current_page === data.productos.last_page){
                    this.stop = true;
                }
                else {
                    this.stop = false;
                }
            } catch (e){
                this.errorCatch(e);
            } finally {
                this.cargando = false;
            }
        },
    }
}
</script>
<style lang="scss" scoped>

.panel-categories{
  height: calc(100vh - 80px);
  width: 245px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000014;
  @media (min-width: 300px) and (max-width: 1024px) {
      height: calc(100vh - 96px);
  }
  @media (min-width: 300px) and (max-width: 1000px) {
      display: none !important;
  }
}

.img-categories{
  box-shadow: 0px 3px 6px #00000029;
}

.tabs-mobile{
  display: none !important;
}

.banner-invitado{
    height: 113px;
    color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background-image: linear-gradient(to right, #612AD5,#9F4BFF, #F9C7FF,  #612AD5);
    .btn-registrarse{
        background-color: #FFFFFF;
        color: #612AD5;
        font-weight: 500;
        max-width: 182px;
        height: 44px;
        border-radius: 12px;
    }
}

.card-modificado{
    border: 1px solid #FF5806;
    background-color: #FFF3EA;
    //width: 312px;
    border-radius: 8px;
    height: 78px;
    .text-pedidos{
        height: 47px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
    .btn{
        display: flex;
        align-items: center;
        color: #FFFFFF;
        height: 32px;
        border-radius: 12px;
        width: 100%;
        background-color: #FF5806;
    }
}

@media (min-width: 300px) and (max-width: 815px) {

  .title-category{
    display: none !important;
  }  

  .img-categories{
    width: 213px;
    height: 86px;
  }
  
  .tabs-mobile{
    display: block !important;
  }

  .titles{
    font-size: 17px !important;
    font-weight: 500 !important;
  }

  .spacer{
    display: none !important;
  }

  .text-arrow{
    display: none !important;
  }
  .like-bar{
    display: none !important;
  }

  
}


@media (min-width: 300px) and (max-width: 1024px) {
    .col.home-page{
          height: calc(100vh - 106px) !important;
      }
}

</style>
